<!--
 * @Autor: yzr
 * @Date: 2021-09-17 17:01:13
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-29 16:16:11
 * @Description:
-->
<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';
import productList from './table/table.js';

formCreate.component('productList', productList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单
      formFunctionCode: 'cps_terminal_integral_form',
    };
  },
  methods: {
    formComplete() {
      this.getDetail();
    },
    getDetail() {
      request.get('/cps-mall/v1/order/terminalOrderDetails/findOrderById', {
        id: this.formConfig.id,
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        let goodsName = '';
        let number = 0;
        if (res.result.goodsList) {
          res.result.goodsList.forEach((item, index) => {
            if (index < res.result.goodsList.length - 1) {
              goodsName += `${item.goodsName}；`;
            } else {
              goodsName += `${item.goodsName}`;
            }
            number += item.number;
          });
        }
        const result = {
          ...this.formConfig,
          ...res.result.baseInfo,
          ...res.result.details,
          productList: res.result.goodsList.map((item) => ({
            ...item, price: item.price * item.number,
          })),
          goodsName,
          number,
          status: this.formConfig.status.toString(),
          totalPrice: this.formConfig.totalPrice,
        };
        this.setValue({
          ...result,
        });
      });
    },
    submit() {
      const params = {
        pazjofxkyvyoosot: encodeKey128(
          this.getFormData().reset_password.password,
        ),
        ids: this.formConfig.selectRow.map((a) => a.id),
      };
      request
        .post('/mdm/mdmUserController/forceChangePassword', params)
        .then((res) => {
          console.log(788888, res);
          if (res.success) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
    },
  },
};
</script>
