var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            border: "",
            "show-overflow": "",
            data: _vm.productList,
            "seq-config": { startIndex: 0 },
          },
        },
        [
          _c("vxe-column", {
            attrs: { type: "seq", title: "序号", width: "60" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "pictureForList",
              title: "积分商品列表图",
              "class-name": "formatter-image",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: row.pictureForList,
                        "preview-src-list": [row.pictureForList],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { field: "goodsCode", title: "积分商品编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "goodsName", title: "积分商品名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "price", title: "实付积分" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "number", title: "兑换数量" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }